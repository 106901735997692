import SectionParcelasHome from "../modules/SectionParcelasHome";
import LoteoContactHome from "../modules/LoteoContactHome";

const BodyHome = () => {
  return (
    <div>
      <SectionParcelasHome />
      <LoteoContactHome />
    </div>
  );
};
export default BodyHome;
